@import "src/styles/settings/settings";

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .pagination-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

.grid-table {
  display: grid;
  margin-top: 24px;
  overflow-x: auto;
  width: 100%;
}

.grid-header {
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  font-size: 21px;
  &:first-child {
    justify-content: flex-start;
  }
}

.grid-header.clickable {
  cursor: pointer;
}

.grid-header-sort-indicator {
  font-size: 14px;
  margin-top: 3px;
  padding-left: 10px;
}

.grid-item {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid color(white, 0.5);
  &:first-child {
    justify-content: flex-start;
  }
}

.grid-line {
  display: contents;
}

.grid-line:hover .grid-item {
  cursor: pointer;
  border-bottom: 1px solid color(white);
}

.dots-actions-wrapper {
  position: relative;
  z-index: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dots-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dots {
  width: 6px;
  height: 6px;
  background-color: white;
  border-radius: 50%;
  margin: 0 2px;
  transition: background-color 0.3s;
}

.dots-actions-wrapper:hover .dots {
  background-color: #555;
}

.children-wrapper {
  display: none;
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translate(-30px, -50%);
  background-color: color(main);
  border: 1px solid color(white);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dots-actions-wrapper.active .children-wrapper {
  display: block;
}
