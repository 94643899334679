@import "src/styles/settings/settings";

.user-list-wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @include media(">=lphone") {
    display: flex;
    grid-template-columns: 45% 45%;
    column-gap: 20px;
    row-gap: 30px;
  }
  @include media(">=laptop") {
    display: flex;
    grid-template-columns: 30% 30% 30%;
    column-gap: 20px;
    row-gap: 30px;
  }
}

.table-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .pagination-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}

.grid-table {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  margin-top: 24px;
  overflow-x: auto;
  width: 100%;
}

.grid-header {
  font-weight: bold;
  text-align: center;
  padding: 16px;
  font-size: 21px;
}

.grid-item {
  padding: 16px 8px;
  text-align: center;
  border-bottom: 1px solid color(white, 0.5);
}

.grid-line {
  display: contents;
}

.grid-line:hover .grid-item {
  cursor: pointer;
  border-bottom: 1px solid color(white);
}

.action-item:not(:last-child) {
  margin-bottom: 4px;
}
