.content-info {
  width: 100%;
  display: flex;
  justify-content: space-between;

  &_wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .info-row {
    display: flex;
    margin-bottom: 10px;
  }

  .button {
    margin-left: 15px;
    height: 100%;
    cursor: pointer;
  }
}
